@import 'react-toastify/dist/ReactToastify.css';

* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

html {
  background: #f2fafe;
}

.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 1px;
  padding: 8.5px 14px;
  padding-right: 0;
  font-size: 12px;
}

nav .header-link {
  color: black;
  font-weight: 600;
  margin-bottom: -22px;
  margin-left: 28px;
  margin-right: 22px;
  padding-bottom: 20px;
  user-select: none;
}

nav .header-link.active {
  border-bottom: 2px solid #25a9e0 !important;
  border-bottom-width: 3px;
  color: #25a9e0;
  padding-bottom: 20px;
}

.Chips {
  margin-top: 5px !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #104960;
  border-radius: 6px;
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

input[type='file'] {
  opacity: 0;
}

.error {
  font-size: 12px;
  color: red;
}

.FileDropzone {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: white;
  border-radius: 5px;
  border: 2px dashed rgb(199, 199, 199);
  margin-top: 4px;
  padding: 8px;
  font-size: 12px;
}

.FileDropzoneDrop {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #daedff;
  border-radius: 5px;
  border: 2px dashed #989898;
  margin-top: 4px;
  padding: 8px;
  font-size: 12px;
}

.FileDropzoneDrop > p {
  color: gray;
}

.FileDropzoneDrop > p {
  color: rgb(22, 22, 22);
}

.customStyles {
  padding: 10;
}

.css-1h51icj-MuiAutocomplete-root
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-input {
  width: 0;
  font-size: 13px;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px !important;
}

.css-1kh9gt5-MuiButtonBase-root-MuiChip-root {
  width: 170px !important;
  border: 1.5px solid rgb(206, 206, 206) !important;
}

.css-23qvg6-MuiButtonBase-root-MuiChip-root,
.css-23qvg6-MuiButtonBase-root-MuiChip-root.Mui-focusVisible {
  width: 170px !important;
}

.close-btn {
  background: #e4e4e4;
  border-radius: 6px;
  border: 1px solid black;
  cursor: pointer;
  padding: 7px 20px;
  text-align: center;
  font-size: 12px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  position: absolute;
  right: 30px;
  top: 30px;
}

.close-btn:hover {
  background: #fdfdfd;
}

.file-container {
  width: 100%;
  height: 100vh;
  background: #070f20c9;
  position: fixed;
  left: 0px;
  top: 3.7rem;
  display: flex;
  align-items: center;
  justify-items: center;
  z-index: 12;
}

.view-btn {
  font-size: 13px;
  position: relative;
  cursor: pointer;
  text-decoration: underline #551a8b;
  top: -24px;
  left: 120px;
  color: #551a8b;
  font-weight: bold;
  font-family: 'Inter';
}

.view-btn:hover {
  text-decoration: underline rgb(147, 147, 253);
}

.file-box {
  width: 80vh;
  object-fit: cover;
  cursor: zoom-in;
  margin: 0px auto;
  border: 2px solid white;
  margin-top: -40px;
  border-radius: 6px;
  transition: transform 0.2s;
}

.file-box:hover {
  transform: scale(1.1);
}

.css-14x8up1 {
  gap: 2px !important;
}

.css-6s4j66 {
  box-shadow: 0px 2px 10px 0px #10496030;
  border-radius: 10px !important;
}

.css-1vpwvbd,
.css-4qjilf,
.css-cxmp86,
.css-1l25s6i,
.css-1knk1k2,
.css-1av56s2,
.css-nt563w,
.css-qsf0nv,
.css-1ap1fie,
.css-1dhbl4c,
.css-in57hs {
  border-radius: 8px !important;
}

.css-4ze8un {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.css-nt563w {
  background: white !important;
}

.css-1ouein-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #104960 !important;
  color: #fff !important;
  border: 1px solid rgb(184, 184, 184);
}

/* .css-1o84d0n{
  padding: 0 !important;
  background-color:transparent !important;
  border-bottom: none !important;
} */
.BreadCrumbStyle {
  padding-inline: 120px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #e5f7ff;
  border-bottom: 1px solid #e0e0e0;
}

.RemoveBreadCrumbStyle {
  padding-inline: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
  border-bottom: none;
}
.pdfPage {
  /* width: 50vh; */
  height: 80vh;
  background: rgb(94, 92, 92);
  overflow: scroll;
  padding: '2px';
}
.react-pdf__Page {
  padding: 20px;
}
